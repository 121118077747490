import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				AutoMasters
			</title>
			<meta name={"description"} content={"Точний автосервіс для кожної поїздки"} />
			<meta property={"og:image"} content={"https://nimbelix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nimbelix.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nimbelix.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nimbelix.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nimbelix.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nimbelix.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nimbelix.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 40px 100px 40px" sm-padding="40px 20px 40px 20px" quarkly-title="Images-23">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				max-width="none"
				width="100%"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Box min-width="100px" min-height="100px" width="50%">
				<Text
					color="--darkL2"
					margin="0px 0px 20px 0px"
					font="--headline2"
					text-align="left"
					lg-width="100%"
					lg-text-align="left"
					lg-font="--headline3"
					sm-margin="16px 0px 0px 0px"
					letter-spacing="2px"
					text-transform="uppercase"
				>
					Випробуйте наш автосервіс
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					align-self="flex-end"
					lg-width="100%"
					lg-max-width="540px"
					lg-align-self="auto"
				>
					В автосервісі "Автомайстер" ми пропонуємо комплексний набір послуг для забезпечення безперебійної та безпечної роботи вашого автомобіля. Наша прихильність до якості та задоволеності клієнтів спонукає нас надавати широкий спектр послуг, пристосованих для задоволення унікальних потреб кожного автомобіля та його власника. Від планового технічного обслуговування до складного ремонту, AutoMasters - це ваше джерело для задоволення всіх ваших автомобільних потреб.
				</Text>
			</Box>
			<Image
				src="https://nimbelix.com/img/7.jpg"
				display="block"
				width="60%"
				lg-order="-1"
				sm-width="100%"
			/>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Наші послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Регулярне технічне обслуговування: Підтримуйте свій автомобіль в ідеальному стані за допомогою наших регулярних послуг з технічного обслуговування.
						<br />
						<br />
						{"   "}Заміна оливи: Необхідна для здоров'я та довговічності двигуна.{"\n "}
						<br />
						<br />
						{"  "}Перевірка гальм: Забезпечення вашої безпеки на кожній зупинці.
					</Text>
				</Box>
				<Image src="https://nimbelix.com/img/8.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://nimbelix.com/img/9.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Удосконалена діагностика: Ми використовуємо найсучасніші технології для швидкого і точного виявлення проблем.
						<br />
						<br />
						{"   "}Діагностика двигуна: Виявлення та усунення проблем з двигуном до того, як вони загостряться.
						<br />
						<br />
						{"   "}Оцінка електричної системи: Комплексні перевірки, щоб переконатися, що всі системи працюють.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Дізнайтеся більше в AutoMasters
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Це лише короткий огляд того, що ми пропонуємо в сервісному центрі "Автомайстер". Для повного розуміння того, як ми можемо допомогти з вашими конкретними автомобільними потребами, ми запрошуємо вас зв'язатися з нами або відвідати наш центр. Переконайтеся на власному досвіді, як ми можемо підвищити продуктивність і довговічність вашого автомобіля.
					</Text>
					<Button
						href="/contact"
						type="link"
						text-decoration-line="initial"
						background="--color-primary"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Контакти
					</Button>
				</Box>
				<Image src="https://nimbelix.com/img/10.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<Components.FooterNav />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});